import { formatCurrencyCompactLong } from "@/plugins/filters";
import { authMapGetters } from "@/store/modules/auth";
import { quoteMapActions } from "@/store/modules/quote";
import Vue from "vue";
import { Skeleton } from "element-ui";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import { displayXML } from "@/helpers/displayXML";

interface IRatingsData {
  expanded: boolean;
  error: string;
}
export default Vue.extend({
  components: {
    Skeleton,
    CustomAlert
  },
  data(): IRatingsData {
    return {
      expanded: false,
      error: ""
    };
  },
  props: {
    hideTopBorder: {
      type: Boolean,
      required: false
    },
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      required: true,
      default: ""
    },
    rating: {
      type: Object,
      required: true,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    ...quoteMapActions(["getSendingOrReceivingXML"]),
    toggleExpansion() {
      this.expanded = !this.expanded;
    },
    formatCurrency(value: any) {
      if ((value && !isNaN(value)) || value === 0) {
        return formatCurrencyCompactLong(value);
      } else {
        return "N/A";
      }
    },
    async sendingXMLHandler(responseType: "VS" | "VR") {
      await this.getXMLHandler(this.rating._id, responseType);
    },
    async getXMLHandler(ratingId: string, responseType: "VS" | "VR") {
      if (!ratingId || !responseType) {
        this.error = "No rating ID OR response type";
        return;
      }
      try {
        const response = await this.getSendingOrReceivingXML({
          rateId: ratingId,
          responseType: responseType as any
        });
        const { rawXML } = response.data;
        displayXML(rawXML);
      } catch (error) {
        this.error = error.message as any;
      }
    }
  },
  computed: {
    ...authMapGetters(["isCurrentUserAdmin"]),
    rate(): any {
      return this.rating.rate;
    },
    faIcon(): string {
      return this.expanded ? "chevron-up" : "chevron-right";
    }
  }
});
