
import { downloadQuoteDocument } from "@/helpers/downloadLossForm";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import {
  quoteMapActions,
  quoteMapMutations,
  quoteMapState
} from "@/store/modules/quote";
import { get } from "lodash";
import { validateYearBuilt } from "@/helpers/validateYearBuilt";
import ErrorsList from "./ErrorsList.vue";
import VueWithMixins from "@/helpers/mixins";
import RatingsMixin from "./RatingsMixin";
import ApprovalCode from "@/components/Quotes/ApprovalCode";

export default VueWithMixins(RatingsMixin, ApprovalCode).extend({
  name: "rating-viewer",
  components: {
    CustomAlert,
    ErrorsList,
    HOViewer: () =>
      import(
        "@/views/shared/quotes/Components/RatingDetailViewers/HOViewer.vue"
      ),
    TenantCondoViewer: () =>
      import(
        "@/views/shared/quotes/Components/RatingDetailViewers/TenantCondoViewer.vue"
      ),
    DwellingFireViewer: () =>
      import(
        "@/views/shared/quotes/Components/RatingDetailViewers/DwellingFireViewer.vue"
      )
  },
  data() {
    return {
      loading: false,
      expanded: false,
      insuredFirstName: "",
      insuredLastName: "",
      error: "",
      needsApproval: false,
      needsYearBuiltApproval: false
    };
  },
  mounted() {
    if (
      this.editing &&
      this.editing.quoteApplication &&
      this.editing.quoteApplication.applicant
    ) {
      this.insuredFirstName = get(
        this.editing.quoteApplication.applicant,
        "firstName"
      );
      this.insuredLastName = get(
        this.editing.quoteApplication.applicant,
        "lastName"
      );
    }

    this.checkNeedsApprovalCode();
  },
  watch: {
    value: {
      handler(result) {
        if (result == this.rating.id) {
          if (this.showUnderwriterCodeField) {
            this.expanded = true;
            this.$emit("needsApprovalCode", true);
          } else {
            this.$emit("needsApprovalCode", false);
          }
        }
      }
    }
  },
  methods: {
    ...quoteMapActions(["updateQuoteApplication"]),
    ...quoteMapMutations(["SET_EDIT", "SET_EDIT_FIELD"]),
    ...quoteMapMutations({
      editingField: "SET_EDIT_FIELD"
    }),
    handleApprovalCheckBox(checkValue: any): void {
      this.editingField({ key: "hasUnderwriterApproved", value: checkValue });
      if (!checkValue) {
        this.editingField({ key: "approvalCode", value: "" });
      }
    },
    async saveQuoteApplication(): Promise<void> {
      try {
        const quoteId = this.$route.params.quoteId;
        this.loading = true;
        const response = await this.updateQuoteApplication({
          quoteId,
          updates: {
            applicant: {
              firstName: this.insuredFirstName,
              lastName: this.insuredLastName
            }
          }
        });
        this.SET_EDIT(response.data);
      } catch (error) {
        this.$appNotifyError(
          "There was an error saving your responses. Try again later."
        );
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    hasCosmeticEndorsement(ratingData: {
      rate: { Quote: { m_Endorsement: any } };
    }): string {
      let has145 = false;
      const rate = get(ratingData, "rate", {});
      const Quote = get(rate, "Quote", {});
      const m_Endorsement = get(Quote, "m_Endorsement", {});

      if (Object.keys(m_Endorsement).length > 0) {
        has145 = `E_145` in m_Endorsement;
      }

      return has145 ? this.signedCosmeticMessage : "";
    },
    printQuote(): void {
      if (this.insuredFirstName && this.insuredLastName) {
        this.doPrintQuote();
      } else {
        this.$modal.show(`input-insured-name-modal${this.rating._id}`);
      }
    },
    async saveAndPrint(): Promise<void> {
      await this.saveQuoteApplication();
      this.doPrintQuote();
    },
    async doPrintQuote(): Promise<void> {
      this.$modal.hide(`input-insured-name-modal${this.rating._id}`);
      await downloadQuoteDocument({
        quoteIds: [this.rating.quoteId],
        rateId: this.rating._id,
        printFor: "rate"
      });
    },
    checkNeedsApprovalCode() {
      if (
        this.editing &&
        this.editing.yearOfRoof &&
        this.editing.policyType !== "T"
      ) {
        const { success, message, type } = this.checkYearOfRoof(
          this.editing.yearOfRoof,
          this.editing.yearBuild,
          this.editing.roofMaterial,
          [this.rating.companyNumber],
          this.rating.formType,
          this.rating.ratingWindIncluded
        );
        if (!success && type === "call") {
          this.messages.push(message);
        }
      }
      const { success, reason } = validateYearBuilt(this.editing.yearBuild, [
        this.rating.companyNumber
      ]);

      if (!success) {
        this.messages.push(reason);
        this.needsYearBuiltApproval = true;
      } else {
        this.needsYearBuiltApproval = false;
      }
    },
    signedExclusionMessageFormatted(message: string) {
      if (this.hasValidUnderwriterDetails) {
        message = `${message}, ${this.underwriterDetails.firstName} ${this.underwriterDetails.lastName}`;
      }
      return `${message}.`;
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    signedWindExclusionMessage(): string {
      const message =
        "Signed Wind Exclusion MUST be mailed to your Underwriter";
      return this.signedExclusionMessageFormatted(message);
    },
    signedCosmeticMessage(): string {
      const message =
        "Signed Cosmetic Damage Exclusion and supporting documents MUST be mailed to your Underwriter";
      return this.signedExclusionMessageFormatted(message);
    },
    rate(): any {
      return this.rating.rate;
    },
    faIcon(): string {
      return this.expanded ? "chevron-up" : "chevron-right";
    },
    showUnderwriterCodeField(): boolean {
      return this.needsApproval
        ? this.needsApproval
        : this.needsYearBuiltApproval;
    },
    underwriterDetails(): Record<string, any> {
      return get(this.editing, "quoteUnderwriter", {});
    },
    hasValidUnderwriterDetails(): boolean {
      return !!(
        this.underwriterDetails &&
        Object.keys(this.underwriterDetails).length &&
        this.underwriterDetails.email
      );
    }
  }
});
